<template>
    <div class="app-container">
        <el-input
            v-model="queryInfo.query"
            clearable
            size="small"
            placeholder="输入名称或者邮箱搜索"
            style="width: 200px;"
            class="filter-item"
            @keyup.enter.native="crud.toQuery"
        />
        <span>
            <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="getTableData()">搜索</el-button>
        </span>
        <div  class="crdc-opts">
          <span>
            <slot name="left" />
            <!-- <el-button
              class="filter-item"
              size="mini"
              type="success"
              icon="el-icon-edit"
            >
              修改
            </el-button>
            <el-button
              slot="reference"
              class="filter-item"
              type="danger"
              icon="el-icon-delete"
              size="mini"
            >
              删除
            </el-button> -->
          </span>
          <!--右侧-->
            <slot name="right" />
          <el-button-group class="crud-opts-right">
              <el-button
                size="mini"
                icon="el-icon-refresh"
                @click="getTableData()"
              />
            </el-button-group>
        </div>
        <el-table
        :data="tableData"  highlight-current-row
        @current-change="companyCurrentChange"
        border stripe
        style="width: 100%">
        <el-table-column
          fixed
          prop="company_ID"
          label="关联公司"
          min-width="80">
        </el-table-column>
        <el-table-column
          fixed
          prop="company_NAME"
          label="公司名称"
          min-width="150">
        </el-table-column>
        <el-table-column
          prop="news_LEVEL_NAME"
          label="舆情级别"
          min-width="150">
        </el-table-column>
        <el-table-column
          prop="news_CONTENT"
          label="舆情内容"
          min-width="400">
        </el-table-column>
        <el-table-column
          prop="task_STATUS_NAME"
          label="任务状态"
          min-width="80">
        </el-table-column>
        <el-table-column
          prop="sponse_NAME"
          label="任务发起人"
          min-width="100">
        </el-table-column>
        <el-table-column
          prop="process_NAME"
          label="最后任务处理人"
          min-width="110">
        </el-table-column>
        <el-table-column
          prop="process_OPINION"
          label="意见"
          min-width="150">
          <template slot-scope="scope">
            <input  type="text" v-model="scope.row.process_OPINION" class="cell-input"/>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_DATETIME"
          label="创建时间"
          min-width="150" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
          label="操作" align="center"
          min-width="180">
          <template slot-scope="scope">
            <el-button type="primary"  icon="el-icon-check" size="mini" @click="pass(scope.$index, scope.row)" ></el-button>
            <el-button type="danger"  icon="el-icon-back" size="mini" @click="back(scope.$index, scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-card>
          <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[2, 5, 10, 15]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totle"
      ></el-pagination>
      </el-card>
    </div>
</template>
<script>
export default {
  name: 'News',
  created () { //  初始化
    this.getTableData()
  },
  methods: {
    async getTableData () {
      const { data: res } = await this.$http.get('query/news/flow', {
        params: this.queryInfo
      })
      this.tableData = res.list
      this.totle = res.total
    },
    companyCurrentChange (val) {
    },
    async pass (index, row) {
      await this.$http.post('pass/news/flow', row)
      this.getTableData()
    },
    async back (index, row) {
      await this.$http.post('back/news/flow', row)
      this.getTableData()
    },
    // 监听 pagesize改变的事件
    handleSizeChange (newSize) {
      this.queryInfo.pagesize = newSize
      this.getTableData()
    },
    // 监听 页码值 改变事件
    handleCurrentChange (newSize) {
      this.queryInfo.pagenum = newSize
      this.getTableData()
    }
  },
  data () {
    return {
      queryInfo: {
        query: '',
        pagenum: 1,
        // 每页显示多少数据
        pagesize: 10
      },
      totle: 0,
      form: {
        task_ID: '',
        company_ID: '',
        news_LEVEL: '',
        news_CONTENT: ''
      },
      button_value: '1',
      tableData: [],
      addDialogVisible: false,
      all_company_options: [],
      company_options: [],
      news_level_options: []
    }
  }
}
</script>
<style>
.crdc-opts {
  padding: 6px 0;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.crdc-opts .crud-opts-right {
  margin-left: auto;
}
.cell-input{
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.cell-input:focus{
  background:#fdfcfc;
}
</style>
